import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useToasts } from "react-toast-notifications";

const CatalogGame = (props) => {
  const { image, title, platform, status, genre } = props;
  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();

  const placeholder =
    "https://firebasestorage.googleapis.com/v0/b/fire-game-on.appspot.com/o/placeholder.jpg?alt=media&token=39a31e87-b4fc-476b-afbf-c1cda8cdb39f";

  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);

  function addGame() {
    firestore
      .collection("users")
      .doc(uid)
      .collection("games")
      .add({
        ...props,
      })
      .then((docRef) => {
        docRef.update({
          gameId: docRef.id,
        });
      })
      .then(() => {
        addToast(`${title} added to collection.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  }

  return (
    <div>
      <div className="[ card ]">
        <div className="card__image">
          <img src={placeholder} alt="" aria-hidden="true" />
          <img
            loading="lazy"
            className={loaded ? "loaded" : "source"}
            src={image}
            alt={`${title} cover`}
            ref={imgRef}
            onLoad={() => setLoaded(true)}
            onError={(event) => {
              event.target.src = placeholder;
            }}
          />
        </div>
        <h3>{title}</h3>
        <div className="[ card__tags ]">
          {platform && <span className="tag">{platform}</span>}
          {status && <span className="tag tag--tron">{status}</span>}
          {genre && <span className="tag tag--hyper">{genre}</span>}
        </div>
        <div className="[ card__actions ]">
          <button
            className="button button--icon"
            onClick={(event) => {
              event.preventDefault();
              addGame();
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1H6V6L1 6V10H6V15H10V10H15V6L10 6V1Z"
                fill="#FAD5EE"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CatalogGame;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { useToasts } from "react-toast-notifications";

const SignIn = () => {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      history.push("/games");
    }
  }, [history, auth]);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    firebase
      .login(state)
      .then(() => {
        history.push("/games");
      })
      .catch((error) => {
        addToast(`${error}`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  };

  const signInWithGoogle = () => {
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then(() => {
        history.push("/games");
      });
  };

  return (
    <div className="grid">
      <img
        className="[ u-space-auto u-space-bottom-md ]"
        src={logo}
        alt="Game On!"
      />
      <h1 className="[ gap-bottom-400 ]">Sign In</h1>
      <div className="u-space-bottom-md">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email address</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              required
            />
          </div>
          <button className="button" type="submit">
            Sign in
          </button>
          <a className="[ gap-left-400 ]" href="/forgot">
            Forgot password?
          </a>
        </form>
      </div>
      <div className="gap-top-400 gap-bottom-400 u-text-center">OR</div>
      <button
        className="button"
        onClick={(event) => {
          event.preventDefault();
          signInWithGoogle();
        }}
      >
        <span className="button__text">Sign in with Google</span>
      </button>
      <div className="gap-top-400 u-text-center">
        <a href="/signup">Sign up</a>
      </div>
    </div>
  );
};
export default SignIn;

import React, { useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ConfirmDialog from "../components/ConfirmDialog";

function Category(props) {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();
  const { category } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addForm, setAddForm] = useState("");

  const openModal = (form) => {
    setModalIsOpen(true);
    setAddForm(form);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAddForm("");
  };

  function deleteCategory(category) {
    firestore
      .collection("users")
      .doc(uid)
      .update({
        categories: arrayRemove(category),
      })
      .then(() => {
        addToast(`${category} deleted.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  }

  return (
    <>
      <div className="category__item">
        <Link to={`/category/${category}`}>{category}</Link>
        <button
          className="button button--destructive"
          onClick={() => openModal("delete")}
        >
          Delete
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal font-base"
        overlayClassName="overlay"
        appElement={document.getElementById("root")}
      >
        {addForm === "delete" && (
          <ConfirmDialog
            item={category}
            cancel={closeModal}
            confirm={() => deleteCategory(category)}
          />
        )}
      </Modal>
    </>
  );
}

export default Category;

import React, { useState } from "react";
import Header from "../components/Header";
import CatalogGame from "../components/CatalogGame";
import Loader from "../components/Loader";

const Steam = () => {
  const steamKey = process.env.REACT_APP_STEAM_KEY;
  const [steamName, setSteamName] = useState("");
  const [steamId, setSteamId] = useState("");
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLooking, setIsLooking] = useState(false);

  const lookupSteamId = (steamKey, steamName) => {
    setIsLooking(true);
    fetch(
      `https://stark-basin-44426.herokuapp.com/https://api.steampowered.com/ISteamUser/ResolveVanityURL/v0001/?key=${steamKey}&vanityurl=${steamName}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSteamId(data.response.steamid);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLooking(false);
      });
  };

  const lookup = (steamKey, steamId) => {
    setIsLoading(true);
    fetch(
      `https://stark-basin-44426.herokuapp.com/https://api.steampowered.com/IPlayerService/GetOwnedGames/v1/?&include_appinfo=1&include_played_free_games=1&key=${steamKey}&steamid=${steamId}&format=json`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.response.games[0].name);
        data.response.games.forEach((game) => {
          // console.log(game.name);
          setGames((previousGames) => [
            ...previousGames,
            {
              title: game.name,
              image: `https://steamcdn-a.akamaihd.net/steam/apps/${game.appid}/library_hero.jpg`,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSteamNameChange = (event) => {
    setSteamName(event.target.value);
  };

  const hanldeSteamIDSubmit = (event) => {
    event.preventDefault();
    lookupSteamId(steamKey, steamName);
  };

  const handleNameChange = (event) => {
    setSteamId(event.target.value);
  };

  const hanldeSubmit = (event) => {
    event.preventDefault();
    lookup(steamKey, steamId);
  };

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400  gap-bottom-400 ]">
          <h1>Steam</h1>
        </div>
        <div className="grid">
          <form className="gap-bottom-400" onSubmit={hanldeSteamIDSubmit}>
            <label htmlFor="name">Steam name</label>
            <input
              className="gap-bottom-400"
              type="text"
              name="steamName"
              id="steamName"
              onChange={handleSteamNameChange}
            />
            <button className="button" type="submit">
              Lookup Steam ID
            </button>
          </form>
          <form className="gap-bottom-400" onSubmit={hanldeSubmit}>
            <label htmlFor="name">Steam ID</label>
            <input
              className="gap-bottom-400"
              value={isLooking ? "Looking up Steam ID..." : steamId}
              type="text"
              name="name"
              id="name"
              onChange={handleNameChange}
            />
            <button className="button" type="submit">
              Lookup Steam games
            </button>
          </form>
        </div>
        {isLoading && <Loader />}
        <div className="[ grid--cards ] [ gap-bottom-400 ]">
          {games &&
            games.map((game, index) => (
              <CatalogGame key={index} title={game.title} image={game.image} />
            ))}
        </div>
      </main>
    </>
  );
};
export default Steam;

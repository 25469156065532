import React from "react";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import Game from "../components/Game";
import Header from "../components/Header";
import Loader from "../components/Loader";

import CategoriesList from "../components/CategoryList";

const Category = () => {
  const firestore = useFirestore();
  const params = useParams();
  const { addToast } = useToasts();
  const { uid } = useSelector((state) => state.firebase.auth);

  const initCollection = {
    collection: `users/${uid}/games`,
    storeAs: "games",
    orderBy: ["title", "asc"],
  };
  useFirestoreConnect({
    ...initCollection,
    where: ["gameCategories", "array-contains", `${params.id}`],
  });
  const games = useSelector((state) => state.firestore.data.games);

  const onDeleteGame = async (gameId, title) => {
    await firestore.delete(`users/${uid}/games/${gameId}`);
    addToast(`${title} deleted.`, {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
  };

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400 gap-bottom-400 ]">
          <h1>{params.id}</h1>
        </div>
        <div className="[ sidebar ]">
          <CategoriesList />
          <div>
            {!isLoaded(games) ? (
              <Loader />
            ) : isEmpty(games) ? (
              <div className="gap-top-400 u-text-center">No games.</div>
            ) : (
              <div className="[ grid--cards ] [ gap-bottom-400 ]">
                {games &&
                  Object.values(games)
                    .filter((game) => game !== null)
                    .map((game, index) => (
                      <Game
                        key={`${game.gameId}-${index}`}
                        onDeleteGame={onDeleteGame}
                        {...game}
                      />
                    ))}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};
export default Category;

import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Multiselect } from "multiselect-react-dropdown";

const ManageCategories = (props) => {
  const { gameId, title, image, gameCategories } = props;

  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();

  const categories = useSelector((state) => state.firebase.profile.categories);

  const [presentCategory, setPresentCategory] = useState({
    ...gameCategories,
  });

  const onSelect = (selectedList) => {
    setPresentCategory({
      gameCategories: selectedList,
    });
  };

  const onRemove = (selectedList) => {
    setPresentCategory({
      gameCategories: selectedList,
    });
  };

  const updateCategories = (event) => {
    event.preventDefault();
    firestore
      .collection("users")
      .doc(uid)
      .collection("games")
      .doc(gameId)
      .update({ ...presentCategory })
      .then(() => {
        addToast(`Game categories updated.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
    props.onClick(); // Close modal
  };

  return (
    <div className="font-base">
      <div className="[ game-cover ]">
        <h1>{title}</h1>
        <img src={image} alt={`${title} cover`} />
      </div>
      <form onSubmit={updateCategories}>
        <div>
          <label htmlFor="categories">Categories</label>
          <div className="multi-select">
            <Multiselect
              name="categories"
              id="categories"
              options={categories}
              selectedValues={
                gameCategories && typeof gameCategories === "string"
                  ? [gameCategories]
                  : gameCategories
              }
              onSelect={onSelect}
              onRemove={onRemove}
              isObject={false}
              closeIcon="cancel"
              emptyRecordMsg="No categories"
              placeholder="Select one or more category"
              style={{
                multiselectContainer: { border: 0 },
              }}
              required
            />
          </div>
        </div>
        <button className="button" type="submit">
          Update categories
        </button>
      </form>
    </div>
  );
};
export default ManageCategories;

import React, { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";

const UpdateGame = (props) => {
  const {
    gameId,
    image,
    title,
    platform,
    status,
    release,
    anticipated,
  } = props;

  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();
  const [date, setDate] = useState(new Date());
  const [isAnticipated, setIsAnticipated] = useState(false);

  useEffect(() => {
    if (release !== undefined) {
      setDate(release.toDate());
    }
    if (anticipated === true) {
      setIsAnticipated(true);
    }
  }, [release, anticipated]);

  const [presentGame, setPresentGame] = useState({});

  const handleFieldChange = (event) => {
    setPresentGame({
      ...presentGame,
      [event.target.name]: event.target.value,
    });
  };

  const onSelect = (selectedList) => {
    setPresentGame({
      ...presentGame,
      platform: selectedList,
    });
  };

  const onRemove = (selectedList) => {
    setPresentGame({
      ...presentGame,
      platform: selectedList,
    });
  };

  const handleCheckChange = (event) => {
    setPresentGame({
      ...presentGame,
      release: date,
      anticipated: event.target.checked,
    });
    setIsAnticipated(event.target.checked);
  };

  const handleDateChange = (date) => {
    setPresentGame({
      ...presentGame,
      release: date,
    });
    setDate(date);
  };

  const updateGame = (event) => {
    event.preventDefault();
    firestore
      .collection("users")
      .doc(uid)
      .collection("games")
      .doc(gameId)
      .update({ ...presentGame })
      .then(() => {
        addToast(`Game updated.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
    props.onClick(); // Close modal
  };

  const platfromArray = [
    "Dreamcast",
    "GameCube",
    "Mobile",
    "NES",
    "Nintendo 3DS",
    "Nintendo 64",
    "Nintendo Switch",
    "PC",
    "PlayStation",
    "PlayStation 2",
    "PlayStation 3",
    "PlayStation 4",
    "PlayStation 5",
    "Super NES",
    "Super Nintendo",
    "Wii",
    "Wii U",
    "Xbox",
    "Xbox 360",
    "Xbox One",
    "Xbox Series X",
  ];

  return (
    <div className="font-base">
      <div className="[ game-cover ]">
        <h1>{title}</h1>
        <img src={image} alt={`${title} cover`} />
      </div>
      <form onSubmit={updateGame}>
        <div>
          <label htmlFor="platform">Platform</label>
          <div className="multi-select">
            <Multiselect
              name="platform"
              id="platform"
              options={platfromArray}
              selectedValues={
                platform && typeof platform === "string" ? [platform] : platform
              }
              onSelect={onSelect}
              onRemove={onRemove}
              isObject={false}
              closeIcon="cancel"
              emptyRecordMsg="No platforms"
              placeholder="Select one or more platforms"
              style={{
                multiselectContainer: { border: 0 },
              }}
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="status">Status</label>
          <select
            defaultValue={status && status.length > 0 ? status : ""}
            name="status"
            id="status"
            onChange={handleFieldChange}
            required
          >
            <option value="" disabled>
              Select status
            </option>
            <option value="Unplayed">Unplayed</option>
            <option value="Playing">Playing</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
        <div>
          <label className="checkbox" htmlFor="anticipated">
            <span className="checkbox__input">
              <input
                type="checkbox"
                name="anticipated"
                id="anticipated"
                onChange={handleCheckChange}
                defaultChecked={anticipated}
              />
              <span className="checkbox__control">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4142 5.41421L6.82843 14H4.17157L0.585785 10.4142L3.41421 7.58578L5.5 9.67157L12.5858 2.58578L15.4142 5.41421Z"
                    fill="#FAD5EE"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox__label">Anticipated?</span>
          </label>
        </div>
        {isAnticipated && (
          <div>
            <label htmlFor="release">Release date</label>
            <DatePicker
              name="release"
              id="release"
              selected={date}
              onSelected={date}
              onChange={handleDateChange}
            />
          </div>
        )}
        <button className="button" type="submit">
          Update game
        </button>
      </form>
    </div>
  );
};
export default UpdateGame;

import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./pages/PrivateRoute";
import { ToastProvider } from "react-toast-notifications";
import Games from "./pages/GamesRoute";
import Catalog from "./pages/CatalogRoute";
import Steam from "./pages/SteamRoute";
import Collection from "./pages/CollectionRoute";
import CategoriesRoute from "./pages/CategoriesRoute";
import Category from "./pages/CategoryRoute";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import SignIn from "./components/SignIn";
import "./assets/styles/scss/global.scss";
import "./assets/fonts/fonts.css";
import Tabber from "./components/Tabbar";

function App() {
  return (
    <ToastProvider>
      <div className="[ font-base ]">
        <Switch>
          <PrivateRoute path="/games">
            <Games />
          </PrivateRoute>
          <PrivateRoute path="/catalog">
            <Catalog />
          </PrivateRoute>
          <PrivateRoute path="/steam">
            <Steam />
          </PrivateRoute>
          <PrivateRoute path="/categories">
            <CategoriesRoute />
          </PrivateRoute>
          <PrivateRoute path="/category/:id">
            <Category />
          </PrivateRoute>

          <Route path="/collection/:id">
            <Collection />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/forgot">
            <ForgotPassword />
          </Route>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
        <Tabber />
      </div>
    </ToastProvider>
  );
}
export default App;

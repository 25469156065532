import React, { useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import logo from "../assets/images/logo.svg";
import { useToasts } from "react-toast-notifications";

const SignUp = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { addToast } = useToasts();
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    name: "",
  });

  const handleChange = (evt) => {
    setNewUser({
      ...newUser,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore.collection("users").doc(resp.user.uid).set({
          displayName: newUser.name,
        });
      })
      .then(() => {
        addToast(`Account created, return to login.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .catch((error) => {
        addToast(`${error}`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  };

  return (
    <div className="grid">
      <img
        className="[ u-space-auto u-space-bottom-md ]"
        src={logo}
        alt="Game On!"
      />
      <div>
        <h1 className="[ gap-bottom-400 ]">Sign up</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email address</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              required
            />
          </div>
          <button className="button" type="submit">
            Sign up
          </button>
        </form>
        <div className="gap-top-400 u-text-center">
          <a href="/">Back to sign in</a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

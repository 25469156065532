import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import Modal from "react-modal";
import Game from "../components/Game";
import AddGame from "../components/AddGame";
import Header from "../components/Header";
import Loader from "../components/Loader";
import CategoriesList from "../components/CategoryList";

const Games = () => {
  const { uid } = useSelector((state) => state.firebase.auth);

  const [limit, setLimit] = useState(25);

  const initCollection = {
    collection: `users/${uid}/games`,
    storeAs: "games",
    orderBy: ["title", "asc"],
  };
  const [collection, setCollection] = useState(initCollection);

  useFirestoreConnect({ ...collection, limit: limit });

  const games = useSelector((state) => state.firestore.data.games);

  const initFilter = {
    all: false,
    unplayed: false,
    playing: false,
    completed: false,
  };

  const [filter, setFilter] = useState({ ...initFilter, all: true });

  const onHandleStatus = (event) => {
    event.preventDefault();
    const status = event.target.textContent;
    const filterName = event.target.dataset.filter;
    setCollection({ ...collection, where: ["status", "==", status] });
    setFilter({ ...initFilter, [filterName]: true });
  };
  const onHandleAll = (event) => {
    event.preventDefault();
    const filterName = event.target.dataset.filter;
    setCollection(initCollection);
    setFilter({ ...initFilter, [filterName]: true });
  };

  const onHandleMore = (event) => {
    event.preventDefault();
    if (games) {
      setLimit((prevLimit) => prevLimit + 25);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addForm, setAddForm] = useState("");

  const openModal = (form) => {
    setModalIsOpen(true);
    setAddForm(form);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAddForm("");
  };

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400 gap-bottom-400 ]">
          <h1>Collection</h1>
          <div className="[ button-group ]">
            <button
              data-filter="all"
              className={`button ${filter.all ? "active" : ""}`}
              onClick={(event) => onHandleAll(event)}
            >
              All
            </button>
            <button
              data-filter="unplayed"
              className={`button ${filter.unplayed ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Unplayed
            </button>
            <button
              data-filter="playing"
              className={`button ${filter.playing ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Playing
            </button>
            <button
              data-filter="completed"
              className={`button ${filter.completed ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Completed
            </button>
          </div>
          <div>
            <a
              href={`/collection/${uid}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Share collection"
              className="[ button button--icon ] [ gap-right-400 ]"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 4H7L7 11H9L9 4L12 4V3L9 0H7L4 3L4 4Z"
                  fill="#FAD5EE"
                />
                <path d="M2 16L14 16V7H12V14L4 14L4 7H2V16Z" fill="#FAD5EE" />
              </svg>
            </a>
            <button
              className="button"
              onClick={(event) => {
                event.preventDefault();
                openModal("game");
              }}
            >
              Add game
            </button>
          </div>
        </div>
        <div className="[ sidebar ]">
          <CategoriesList />
          <div>
            {!isLoaded(games) ? (
              <Loader />
            ) : isEmpty(games) ? (
              <div className="gap-top-400 u-text-center">No games.</div>
            ) : (
              <div className="[ grid--cards ] [ gap-bottom-400 ]">
                {games &&
                  Object.values(games)
                    .filter((game) => game !== null)
                    .map((game, index) => (
                      <Game key={`${game.gameId}-${index}`} {...game} />
                    ))}
              </div>
            )}
            {games && Object.values(games).length >= 25 && (
              <div>
                <button
                  className="button"
                  onClick={(event) => onHandleMore(event)}
                >
                  More
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal font-base"
          overlayClassName="overlay"
          appElement={document.getElementById("root")}
        >
          {addForm === "game" && <AddGame onClick={closeModal} />}
        </Modal>
      )}
    </>
  );
};
export default Games;

import React, { useState, useEffect, useRef } from "react";

const PublicGame = (props) => {
  const { image, title, platform, status, genre } = props;
  const placeholder =
    "https://firebasestorage.googleapis.com/v0/b/fire-game-on.appspot.com/o/placeholder.jpg?alt=media&token=39a31e87-b4fc-476b-afbf-c1cda8cdb39f";

  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);

  return (
    <div>
      <div className="[ card ]">
        <div className="card__image">
          <img src={placeholder} alt="" aria-hidden="true" />
          <img
            loading="lazy"
            className={loaded ? "loaded" : "source"}
            src={image}
            alt={`${title} cover`}
            ref={imgRef}
            onLoad={() => setLoaded(true)}
            onError={(event) => {
              event.target.src = placeholder;
            }}
          />
        </div>
        <h3>{title}</h3>
        <div className="[ card__tags ]">
          {platform && Array.isArray(platform)
            ? platform.map((item, index) => (
                <span key={index} className="tag">
                  {item}
                </span>
              ))
            : platform && <span className="tag">{platform}</span>}
          {status && <span className="tag tag--tron">{status}</span>}
          {genre && <span className="tag tag--hyper">{genre}</span>}
        </div>
      </div>
    </div>
  );
};
export default PublicGame;

import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import AddCategory from "../components/AddCategory";

const CategoryList = () => {
  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const categories = useSelector((state) => state.firebase.profile.categories);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addForm, setAddForm] = useState("");

  const openModal = (form) => {
    setModalIsOpen(true);
    setAddForm(form);
    if (categories === undefined) {
      firestore.collection("users").doc(uid).update({ categories: [] });
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAddForm("");
  };
  return (
    <>
      <div>
        <h3>Categories</h3>
        <ul className="categories">
          {categories &&
            Object.values(categories).map((category, index) => (
              <li key={`${category}-${index}`}>
                <Link to={`/category/${category}`}>{category}</Link>
              </li>
            ))}
        </ul>
        <button
          className="button button--secondary"
          onClick={(event) => {
            event.preventDefault();
            openModal("category");
          }}
        >
          Add category
        </button>
      </div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal font-base"
          overlayClassName="overlay"
          appElement={document.getElementById("root")}
        >
          {addForm === "category" && <AddCategory onClick={closeModal} />}
        </Modal>
      )}
    </>
  );
};

export default CategoryList;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useParams } from "react-router-dom";
import PublicGame from "../components/PublicGame";
import Header from "../components/Header";
import Loader from "../components/Loader";

const Collection = () => {
  const firestore = useFirestore();
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [limit, setLimit] = useState(25);
  const initCollection = {
    collection: `users/${id}/games/`,
    storeAs: "collection",
    orderBy: ["title", "asc"],
  };
  const [collection, setCollection] = useState(initCollection);
  useFirestoreConnect({ ...collection, limit: limit });
  const games = useSelector((state) => state.firestore.data.collection);

  const userRef = firestore.collection("users").doc(id);

  userRef.get().then((doc) => {
    if (!doc.exists) return;
    setUser(doc.data());
  });

  const initFilter = {
    all: false,
    unplayed: false,
    playing: false,
    completed: false,
  };
  const [filter, setFilter] = useState({ ...initFilter, all: true });

  const onHandleStatus = (event) => {
    event.preventDefault();
    const status = event.target.textContent;
    const filterName = event.target.dataset.filter;
    setCollection({ ...collection, where: ["status", "==", status] });
    setFilter({ ...initFilter, [filterName]: true });
  };
  const onHandleAll = (event) => {
    event.preventDefault();
    const filterName = event.target.dataset.filter;
    setCollection(initCollection);
    setFilter({ ...initFilter, [filterName]: true });
  };

  const onHandleMore = (event) => {
    event.preventDefault();
    if (games) {
      setLimit((prevLimit) => prevLimit + 25);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400 gap-bottom-400 ]">
          {user.displayName && <h1>{`${user.displayName}'s Collection`}</h1>}
          <div className="[ button-group ]">
            <button
              data-filter="all"
              className={`button ${filter.all ? "active" : ""}`}
              onClick={(event) => onHandleAll(event)}
            >
              All
            </button>
            <button
              data-filter="unplayed"
              className={`button ${filter.unplayed ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Unplayed
            </button>
            <button
              data-filter="playing"
              className={`button ${filter.playing ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Playing
            </button>
            <button
              data-filter="completed"
              className={`button ${filter.completed ? "active" : ""}`}
              onClick={(event) => onHandleStatus(event)}
            >
              Completed
            </button>
          </div>
        </div>

        {!isLoaded(games) ? (
          <Loader />
        ) : isEmpty(games) ? (
          <div className="gap-top-400 u-text-center">No games.</div>
        ) : (
          <div className="[ grid--cards ] [ gap-bottom-400 ]">
            {games &&
              Object.values(games)
                .filter((game) => game !== null)
                .map((game, index) => (
                  <PublicGame key={`${game.gameId}-${index}`} {...game} />
                ))}
          </div>
        )}
        {games && Object.values(games).length >= 25 && (
          <div>
            <button className="button" onClick={(event) => onHandleMore(event)}>
              More
            </button>
          </div>
        )}
      </main>
    </>
  );
};
export default Collection;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import Category from "../components/Category";
import AddCategory from "../components/AddCategory";
import Header from "../components/Header";
import Modal from "react-modal";

const CategoriesRoute = () => {
  const categories = useSelector((state) => state.firebase.profile.categories);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addForm, setAddForm] = useState("");

  const openModal = (form) => {
    setModalIsOpen(true);
    setAddForm(form);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setAddForm("");
  };

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400 gap-bottom-400 ]">
          <h1>Categories</h1>
          <div>
            <button
              className="button"
              onClick={(event) => {
                event.preventDefault();
                openModal("category");
              }}
            >
              Add category
            </button>
          </div>
        </div>
        <div>
          {categories &&
            Object.values(categories).map((category, index) => (
              <Category key={`${category}-${index}`} category={category} />
            ))}
        </div>
      </main>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal font-base"
        overlayClassName="overlay"
        appElement={document.getElementById("root")}
      >
        {addForm === "category" && <AddCategory onClick={closeModal} />}
      </Modal>
    </>
  );
};
export default CategoriesRoute;

import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { storage } from "../services/firebase";
import { useToasts } from "react-toast-notifications";
import { Multiselect } from "multiselect-react-dropdown";

const AddGame = (props) => {
  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();
  const [presentGame, setPresentGame] = useState({
    image: "",
    title: "",
    platform: [],
    status: "",
    genre: "",
  });
  const [imageFile, setImageFile] = useState("");

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    setImageFile(image);
  };

  const handleFieldChange = (event) => {
    setPresentGame({
      ...presentGame,
      [event.target.name]: event.target.value,
    });
  };

  const onSelect = (selectedList) => {
    setPresentGame({
      ...presentGame,
      platform: selectedList,
    });
  };

  const onRemove = (selectedList) => {
    setPresentGame({
      ...presentGame,
      platform: selectedList,
    });
  };

  const platfromArray = [
    "Dreamcast",
    "GameCube",
    "Mobile",
    "NES",
    "Nintendo 3DS",
    "Nintendo 64",
    "Nintendo Switch",
    "PC",
    "PlayStation",
    "PlayStation 2",
    "PlayStation 3",
    "PlayStation 4",
    "PlayStation 5",
    "Super NES",
    "Super Nintendo",
    "Wii",
    "Wii U",
    "Xbox",
    "Xbox 360",
    "Xbox One",
    "Xbox Series X",
  ];

  const addGame = (event) => {
    event.preventDefault();
    storage
      .ref(`/images/${uid}/${imageFile.name}`)
      .put(imageFile)
      .then(() => {
        storage
          .ref(`/images/${uid}/`)
          .child(imageFile.name)
          .getDownloadURL()
          .then((url) => {
            firestore
              .collection("users")
              .doc(uid)
              .collection("games")
              .add({ ...presentGame, image: url })
              .then((docReference) => {
                docReference.update({
                  gameId: docReference.id,
                });
              });
          });
      })
      .then(() => {
        addToast(`Game added to collection.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
    props.onClick(); // Close modal
  };

  return (
    <div>
      <h1 className="[ gap-bottom-400 ]">Add game</h1>
      <form onSubmit={addGame}>
        <div>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleImageChange}
          />
        </div>
        <div>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            required
            onChange={handleFieldChange}
          />
        </div>
        <div>
          <label htmlFor="platform">Platform</label>
          <div className="multi-select">
            <Multiselect
              name="platform"
              id="platform"
              options={platfromArray}
              onSelect={onSelect}
              onRemove={onRemove}
              isObject={false}
              closeIcon="cancel"
              emptyRecordMsg="No platforms"
              placeholder="Select one or more platforms"
              style={{
                multiselectContainer: { border: 0 },
              }}
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="status">Status</label>
          <select
            defaultValue="Select status"
            name="status"
            id="status"
            onChange={handleFieldChange}
            required
          >
            <option value="Select status" disabled>
              Select status
            </option>
            <option value="Unplayed">Unplayed</option>
            <option value="Playing">Playing</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
        <div>
          <label htmlFor="genre">Genre</label>
          <select
            defaultValue="Select genre"
            name="genre"
            id="genre"
            onChange={handleFieldChange}
            required
          >
            <option value="Select genre" disabled>
              Select genre
            </option>
            <option value="Action">Action</option>
            <option value="Adventure">Adventure</option>
            <option value="Battle royale">Battle royale</option>
            <option value="Beat 'em up">Beat 'em up</option>
            <option value="Fighting">Fighting</option>
            <option value="FPS">FPS</option>
            <option value="Hack and slash">Hack and slash</option>
            <option value="Horror">Horror</option>
            <option value="JRPG">JRPG</option>
            <option value="MMO">MMO</option>
            <option value="Platformer">Platformer</option>
            <option value="Point and click">Point and click</option>
            <option value="Puzzle">Puzzle</option>
            <option value="Racing">Racing</option>
            <option value="Rhythm">Rhythm</option>
            <option value="Roguelikes">Roguelikes</option>
            <option value="RPG">RPG</option>
            <option value="RTS">RTS</option>
            <option value="Shooter">Shooter</option>
            <option value="Survival">Survival</option>
            <option value="Trivia">Trivia</option>
          </select>
        </div>
        <button className="button" type="submit">
          Add game
        </button>
      </form>
    </div>
  );
};
export default AddGame;

import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import Header from "../components/Header";
import CatalogGame from "../components/CatalogGame";
import Loader from "../components/Loader";

const Catalog = () => {
  const catalog = useSelector((state) => state.firestore.data.catalog);
  //useFirestoreConnect(["catalog", ]);  sync the catalog collection from Firestore into redux

  useFirestoreConnect({
    collection: `catalog`,
    storeAs: "catalog",
    orderBy: ["title", "asc"],
  });

  return (
    <>
      <Header />
      <main>
        <div className="[ sub-header ] [ gap-top-400  gap-bottom-400 ]">
          <h1>Catalog</h1>
        </div>
        {!isLoaded(catalog) ? (
          <Loader />
        ) : isEmpty(catalog) ? (
          <div className="gap-top-400 u-text-center">No games.</div>
        ) : (
          <div className="[ grid--cards ] [ gap-bottom-400 ]">
            {catalog &&
              Object.values(catalog).map((game, index) => (
                <CatalogGame key={`${game.gameId}-${index}`} {...game} />
              ))}
          </div>
        )}
      </main>
    </>
  );
};
export default Catalog;

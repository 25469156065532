import React from "react";
import { NavLink } from "react-router-dom";

const Tabber = () => {
  return (
    <nav className="tabber">
      <ul className="tabber__list">
        <li>
          <NavLink to="/games" activeClassName="active">
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4141 12L16 8.93138L12.3347 3H3.66526L0 8.93138L2.58588 12H4.27126L5.71429 10.2876V8.93138H10.2857V10.2876L11.7287 12H13.4141ZM9 6C9 6.55229 8.55229 7 8 7C7.44772 7 7 6.55229 7 6C7 5.44772 7.44772 5 8 5C8.55229 5 9 5.44772 9 6Z"
                fill="#e62ea8"
              />
            </svg>
            Collection
          </NavLink>
        </li>
        <li>
          <NavLink to="/categories" activeClassName="active">
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H6L9 4H16V14H0V1Z" fill="#e62ea8"></path>
            </svg>
            Categories
          </NavLink>
        </li>
        <li>
          <NavLink to="/catalog" activeClassName="active">
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 2H15V4H1V2Z" fill="#e62ea8" />
              <path d="M1 7H15V9H1V7Z" fill="#e62ea8" />
              <path d="M15 12H1V14H15V12Z" fill="#e62ea8" />
            </svg>
            Catalog
          </NavLink>
        </li>
        <li>
          <NavLink to="/steam" activeClassName="active">
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 0H7V2.08296C6.08835 2.23591 5.2464 2.59444 4.52347 3.10925L3.05026 1.63604L1.63605 3.05025L3.10925 4.52346C2.59444 5.2464 2.23591 6.08834 2.08296 7H0V9H2.08296C2.23591 9.91165 2.59444 10.7536 3.10925 11.4765L1.63603 12.9497L3.05024 14.364L4.52346 12.8907C5.24639 13.4056 6.08834 13.7641 7 13.917V16H9V13.917C9.91166 13.7641 10.7536 13.4056 11.4765 12.8907L12.9498 14.364L14.364 12.9497L12.8907 11.4765C13.4056 10.7536 13.7641 9.91165 13.917 9H16V7H13.917C13.7641 6.08834 13.4056 5.24639 12.8907 4.52346L14.364 3.05025L12.9497 1.63604L11.4765 3.10925C10.7536 2.59444 9.91165 2.2359 9 2.08296V0ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                fill="#e62ea8"
              />
            </svg>
            Steam
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Tabber;

import React from "react";

function ConfirmDialog(props) {
  const { item, cancel, confirm } = props;

  return (
    <div className="font-base flow">
      <h1>Delete {item}</h1>
      <p>Are you sure?</p>
      <div className="button-set">
        <button className="button button--destructive" onClick={confirm}>
          Yes, delete it
        </button>
        <button className="button button--tertiary" onClick={cancel}>
          No way, cancel
        </button>
      </div>
    </div>
  );
}

export default ConfirmDialog;

import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import firebase from "../services/firebase";
import { isLoaded, isEmpty } from "react-redux-firebase";
import logo from "../assets/images/logo.svg";

const Header = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const signOut = () =>
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful
      })
      .catch(function (error) {
        // An error happened
      });

  return (
    <header className="[ site-main ]">
      <img className="" src={logo} alt="Game On!" />
      {isLoaded(auth) && !isEmpty(auth) ? (
        <>
          <nav className="main-navigation">
            <NavLink to="/games" activeClassName="active">
              Collection
            </NavLink>
            <NavLink to="/catalog" activeClassName="active">
              Catalog
            </NavLink>
            <NavLink to="/steam" activeClassName="active">
              Steam
            </NavLink>
          </nav>
          <div className="site-main__profile">
            <span className="[ profile ][ u-space-right-sm ]">
              Ahoy, {profile.displayName}!
            </span>
            {profile.avatarUrl && (
              <img className="player-avatar" src={profile.avatarUrl} alt="" />
            )}
            <button className="button button--link" onClick={() => signOut()}>
              Sign out
            </button>
          </div>
        </>
      ) : (
        <a href="/" className="button">
          Sign in
        </a>
      )}
    </header>
  );
};

export default Header;

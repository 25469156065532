import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="spin"
          d="M21.8078 10.1202C21.4872 8.50839 20.7755 7.00568 19.7417 5.74128C19.3921 5.31372 18.7545 5.31659 18.364 5.70712C17.9734 6.09764 17.9797 6.72775 18.319 7.16352C20.7628 10.302 20.5421 14.8427 17.6568 17.7279C14.5327 20.8521 9.46734 20.8521 6.34314 17.7279C3.45793 14.8427 3.23723 10.302 5.68103 7.16352C6.02034 6.72775 6.02656 6.09764 5.63603 5.70712C5.24551 5.31659 4.60788 5.31373 4.2583 5.74128C3.22449 7.00568 2.51275 8.50839 2.19214 10.1202C1.80629 12.06 2.00433 14.0707 2.7612 15.8979C3.51808 17.7252 4.7998 19.287 6.44429 20.3858C8.08879 21.4846 10.0222 22.0711 12 22.0711C13.9778 22.0711 15.9112 21.4846 17.5557 20.3858C19.2002 19.287 20.4819 17.7252 21.2388 15.8979C21.9957 14.0706 22.1937 12.06 21.8078 10.1202Z"
          fill="#2BD9CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3369 2.00698C13.309 2.04725 13.9458 2.95787 13.8716 3.92805L13.1853 12.9019C13.1379 13.5215 12.6214 14 12 14V14C11.3786 14 10.8621 13.5215 10.8147 12.9019L10.1284 3.92805C10.0542 2.95787 10.691 2.04726 11.6631 2.00698C11.8876 1.99767 12.1124 1.99767 12.3369 2.00698Z"
          fill="#2BD9CE"
        />
        <circle cx="12" cy="17" r="1" fill="#2BD9CE" />
      </svg>
      <span className="loader__text">Loading...</span>
    </div>
  );
};

export default Loader;

import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

const AddCategory = (props) => {
  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { addToast } = useToasts();

  const categories = useSelector((state) => state.firebase.profile.categories);

  const [presentCategory, setPresentCategory] = useState({});

  const handleFieldChange = (event) => {
    setPresentCategory({
      categories: [...categories, event.target.value],
    });
  };

  const addCategory = (event) => {
    event.preventDefault();
    firestore
      .collection("users")
      .doc(uid)
      .update({ ...presentCategory })
      .then(() => {
        addToast(`Category created.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
    props.onClick(); // Close modal
  };

  return (
    <div>
      <h1 className="[ gap-bottom-400 ]">Add category</h1>
      <form onSubmit={addCategory}>
        <div>
          <label htmlFor="categories">Category name</label>
          <input
            type="text"
            name="categories"
            id="categories"
            required
            onChange={handleFieldChange}
          />
        </div>
        <button className="button" type="submit">
          Add category
        </button>
      </form>
    </div>
  );
};
export default AddCategory;

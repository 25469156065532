import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import logo from "../assets/images/logo.svg";
import { useToasts } from "react-toast-notifications";

const ForgotPassword = () => {
  const firebase = useFirebase();
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        addToast(`Email sent.`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .catch(function (error) {
        addToast(`${error}`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      });
  };

  return (
    <div className="grid">
      <img
        className="[ u-space-auto u-space-bottom-md ]"
        src={logo}
        alt="Game On!"
      />
      <div>
        <h1 className="[ gap-bottom-400 ]">Forgot password</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email address</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
          <button className="button" type="submit">
            Forgot password
          </button>
        </form>
        <div className="gap-top-400 u-text-center">
          <a href="/">Back to sign in</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

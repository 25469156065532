import React, { useState, useEffect, useRef } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import UpdateGame from "../components/UpdateGame";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import ManageCategories from "../components/ManageCategories";
import ConfirmDialog from "../components/ConfirmDialog";

const Game = (props) => {
  const { uid } = useSelector((state) => state.firebase.auth);

  const {
    gameId,
    image,
    title,
    platform,
    status,
    genre,
    anticipated,
    release,
  } = props;
  const placeholder =
    "https://firebasestorage.googleapis.com/v0/b/fire-game-on.appspot.com/o/placeholder.jpg?alt=media&token=39a31e87-b4fc-476b-afbf-c1cda8cdb39f";

  const [loaded, setLoaded] = useState(false);
  const firestore = useFirestore();
  const { addToast } = useToasts();
  const imgRef = useRef();
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updateForm, setUpdateForm] = useState("");

  const openModal = (form) => {
    setModalIsOpen(true);
    setUpdateForm(form);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setUpdateForm("");
  };

  const onDeleteGame = async (gameId, title) => {
    await firestore.delete(`users/${uid}/games/${gameId}`);
    addToast(`${title} deleted.`, {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
  };

  return (
    <div>
      <div className="[ card ]">
        <div className="card__image">
          <img src={placeholder} alt="" aria-hidden="true" />
          <img
            loading="lazy"
            className={loaded ? "loaded" : "source"}
            src={image}
            alt={`${title} cover`}
            ref={imgRef}
            onLoad={() => setLoaded(true)}
            onError={(event) => {
              event.target.src = placeholder;
            }}
          />
        </div>
        <h3>{title}</h3>
        {anticipated && (
          <span
            className="anticpated"
            data-tip={release.toDate().toDateString()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8C0 8 3 2 8 2C13 2 16 8 16 8C16 8 13 14 8 14C3 14 0 8 0 8ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
                fill="#ebce13"
              />
            </svg>
            <ReactTooltip />
          </span>
        )}
        <div className="[ card__tags ]">
          {platform && Array.isArray(platform)
            ? platform.map((item, index) => (
                <span key={index} className="tag">
                  {item}
                </span>
              ))
            : platform && <span className="tag">{platform}</span>}
          {status && <span className="tag tag--tron">{status}</span>}
          {genre && <span className="tag tag--hyper">{genre}</span>}
        </div>
        <div className="[ card__actions ]">
          <button
            className="button button--icon"
            onClick={(event) => {
              event.preventDefault();
              openModal("categories");
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H6L9 4H16V14H0V1Z" fill="#FAD5EE" />
            </svg>
          </button>
          <button
            className="button button--icon"
            onClick={(event) => {
              event.preventDefault();
              openModal("game");
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.29289 3.70711L1 11V15H5L12.2929 7.70711L8.29289 3.70711Z"
                fill="#FAD5EE"
              />
              <path
                d="M9.70711 2.29289L13.7071 6.29289L15.1716 4.82843C15.702 4.29799 16 3.57857 16 2.82843C16 1.26633 14.7337 0 13.1716 0C12.4214 0 11.702 0.297995 11.1716 0.828428L9.70711 2.29289Z"
                fill="#FAD5EE"
              />
            </svg>
          </button>
          <button
            className="button button--icon"
            onClick={(event) => {
              event.preventDefault();
              openModal("delete");
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.1716 8L0.585815 3.41421L3.41424 0.585785L8.00003 5.17157L12.5858 0.585785L15.4142 3.41421L10.8285 8L15.4142 12.5858L12.5858 15.4142L8.00003 10.8284L3.41424 15.4142L0.585815 12.5858L5.1716 8Z"
                fill="#FAD5EE"
              />
            </svg>
          </button>
        </div>
      </div>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
          appElement={document.getElementById("root")}
        >
          {updateForm === "game" && (
            <UpdateGame onClick={closeModal} {...props} />
          )}

          {updateForm === "categories" && (
            <ManageCategories onClick={closeModal} {...props} />
          )}

          {updateForm === "delete" && (
            <ConfirmDialog
              item={title}
              cancel={closeModal}
              confirm={() => {
                onDeleteGame(gameId, title);
              }}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
export default Game;
